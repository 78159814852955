import React, { useState} from "react";
import { next, prev } from "./imgs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SuccessModal from "./success-modal";
import { useDispatch } from "react-redux";
import { setPlaceData, setSearchText } from "../../reducer/redux";

const SearchConnect = () => {

  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [success,setSuccess]=useState(false)

    const handleSendSuccess=()=>{
        setSuccess(!success)
    }

    const handleBackMenu=()=>{
      navigate('/')
      dispatch(setSearchText(""))
      dispatch(setPlaceData([]))
      const body = document.querySelector(".app");
        body.classList.remove("blur-effect");
      setSuccess(false)

    }
  return (
    <main className="max-lg:w-11/12 w-10/12  mt-[144px] max-w-[1440px] mx-auto flex flex-col justify-center items-center gap-[24px]">
      <section className="flex flex-col gap-[8px] max-lg:w-11/12 w-[70%]">
        <h1 className="text-[#fdfdfd] font-[600] text-[30px]">
          Joy haqida ma’lumotlar
        </h1>
      </section>
      <div className="max-lg:w-11/12 w-[70%] h-[1px] bg-[#333]"></div>
      <section className="max-lg:w-11/12 w-[70%] step-content sm:p-[56px] flex flex-col gap-[32px]">
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Familiya ism
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"Familiya va ismingizni kiriting"}
            name="full_name"
            // value={formData.full_name}
            // onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Telefon raqami
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"+998"}
            name="full_name"
            // value={formData.full_name}
            // onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >
            Joy nomi
          </label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  p-[10px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"Joy nomini kiriting"}
            name="full_name"
            // value={formData.full_name}
            // onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <section className="w-full max-sm:bg-[#0A090C] max-sm:px-[20px] pb-[32px] pt-[12px] max-sm:z-10 border-t border-[#1F242F] right-0 max-sm:fixed max-sm:bottom-0 max-sm:grid grid-cols-2 flex justify-start items-start gap-[24px]">
          <button
            onClick={() => navigate(-1)}
            className={` max-sm:w-full px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] btn-prev `}
          >
            <img src={prev} alt="" />
            <h1 className="max-sm:text-[16px] text-[14px] font-[500]">Orqaga</h1>
          </button>
          <button onClick={handleSendSuccess} className="px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] bg-[#2970FF] rounded-[8px]">
            <h1 className="max-sm:text-[16px] text-[14px] font-[500]">Yuborish</h1>
            <img src={next} alt="" />
          </button>
        </section>
      </section>
      <SuccessModal handleClose={handleSendSuccess} handleBackMenu={handleBackMenu} isOpen={success}/>
    </main>
  );
};

export default SearchConnect;
