import React, { useState } from "react";
import Stepper from "./steepper";
import StepperControl from "./stepper-control";
import "./add-place.scss";
import { useTranslation } from "react-i18next";
import PlaceAbout from "./steps/place-about";
import Photos from "./steps/photos";
import PlaceLocation from "./steps/place-location";
import PlaceType from "./steps/place-type";
import PlaceTime from "./steps/place-time";
import AdditionInfo from "./steps/addition-info";
import Links from "./steps/links";
import TimeZone from "./steps/time-zone";
import Applicant from "./steps/applicant";
import StepperMobile from "./stepper-mobile";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import SuccessModal from "./success-modal";
import {clearFetchPlace, setPlaceData, setSearchText, setStep} from "../../reducer/redux";
import {useNavigate} from "react-router-dom";

const AddPlace = () => {
  const { t } = useTranslation();
  const {fetchPlace, currentStep}=useSelector(state=>state.events)
  // const [currentStep, setCurrentStep] = useState(1);
  const steps = [
    t("step1"),
    t("step2"),
    t("step3"),
    t("step4"),
    t("step5"),
    t("step6"),
    // t("step7"),
    // t("step8"),
    t("step9"),
  ];
  // fetchPlace: {
  //   name: "",
  //   phone: "",
  //   phone2: "",
  //   image: "",
  //   latitude: "",
  //   longitude: "",
  //   place_type: {},
  //   work_start_time: "",
  //   work_end_time: "",
  //   work_days: [],
  //   website: "",
  //   instagram: "",
  //   telegram: "",
  //   telegram_bot: "",
  //   facebook: "",
  //   twitter: "",
  //   youtube: "",
  //   info: "",
  //   full_address: "",
  //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZonem,
  //   user_name: "",
  //   status: "",
  //   created_at: "",
  //   about: null,
  //   applicantName:"",
  //   applicantPhone:""
  // },
  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <PlaceAbout />;
      case 2:
        return <Photos />;
      case 3:
        return <PlaceLocation />;
      case 4:
        return <PlaceType />;
      case 5:
        return <PlaceTime />;
      case 6:
        return <Links />;
      case 7:
        return <Applicant />;
      // case 8:
      //   return <AdditionInfo />;
      // case 7:
      //   return <Applicant />;
      default:
    }
  };

  const [success,setSuccess]=useState(false)
  const dispatch=useDispatch()
  const navigate = useNavigate()
  const handleSendSuccess=()=>{
    setSuccess(!success)
  }

  const handleBackMenu=()=>{
    navigate('/')
    dispatch(setSearchText(""))
    dispatch(setPlaceData([]))
    const body = document.querySelector(".app");
    body.classList.remove("blur-effect");
    setSuccess(false)
  }

  const handleClick = (direction) => {
    // let newStep = currentStep;
    // direction === "next" ? newStep++ : newStep--;
    // newStep > 0 && newStep <= steps.length && dispatch(setStep(newStep));
    
/*    if(currentStep===steps.length){
      const formData=new FormData();
      formData.append("name",fetchPlace?.name);
      formData.append("phone",fetchPlace?.phone);
      formData.append("phone2",fetchPlace?.phone2);
      formData.append("image",fetchPlace?.image);
      formData.append("latitude",fetchPlace?.latitude);
      formData.append("longitude",fetchPlace?.longitude);
      formData.append("place_type",JSON.stringify(fetchPlace?.place_type));
      formData.append("work_start_time",fetchPlace?.work_start_time);
      formData.append("work_end_time",fetchPlace?.work_end_time);
      formData.append("work_days",JSON.stringify(fetchPlace?.work_days));
      formData.append("website",fetchPlace?.website);
      formData.append("instagram",fetchPlace?.instagram);
      formData.append("telegram",fetchPlace?.telegram);
      formData.append("telegram_bot",fetchPlace?.telegram_bot);
      formData.append("facebook",fetchPlace?.facebook);
      formData.append("twitter",fetchPlace?.twitter);
      formData.append("youtube",fetchPlace?.youtube);
      formData.append("info",fetchPlace?.info);
      formData.append("full_address",fetchPlace?.full_address);
      formData.append("timezone",fetchPlace?.timezone);
      formData.append("user_name",fetchPlace?.user_name);
      formData.append("status",fetchPlace?.status);
      formData.append("created_at",fetchPlace?.created_at);
      formData.append("user_name",fetchPlace?.applicantName);
      formData.append("user_phone",fetchPlace?.applicantPhone);
      const fetchDat=async()=>{
        try {
          const res = await axios({
            method:"POST",
            headers:{
              "Content-Type":"multipart/form-data"
            },
            url:"https://admin13.uz/api/new-place/",
            data:formData
          })
          if (!!res.data) {
            handleSendSuccess();
            dispatch(clearFetchPlace());  // Clear the Redux state
          }
          console.log(res)
        } catch (error) {
          console.log(error)
        }
      }
      fetchDat()
    }*/
  };
  console.log(fetchPlace)
  return (
    <main className="add-place max-md:w-11/12 md:w-10/12 max-sm:mt-[90px]  sm:mt-[144px] max-w-[1440px] mx-auto">
      <section>
         <StepperMobile
          currentStep={currentStep}
          steps={steps}
          handleClick={handleClick}
        />
      </section>
      <section className="stepper">
        <Stepper steps={steps} currentStep={currentStep} />
      </section>
      <section className="step-content sm:p-[56px]">
        <div>
          {displayStep(currentStep)}
        </div>
        {/*<StepperControl*/}
        {/*  currentStep={currentStep}*/}
        {/*  steps={steps}*/}
        {/*  handleClick={handleClick}*/}
        {/*/>*/}
      </section>
      {/*<SuccessModal handleClose={handleSendSuccess} handleBackMenu={handleBackMenu} isOpen={success}/>*/}
    </main>
  );
};

export default AddPlace;
