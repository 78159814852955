import ReactPaginate from "react-paginate";
import { next, prev } from "./pagination-img";
import "./pagination.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingState, setPlaceData } from "../../../reducer/redux";

const Pagination = ({ pageCount }) => {
  const dispatch = useDispatch();
  const {searchText}=useSelector(state=>state.events)
  const handlePageClick = ({ selected }) => {
    const fetchData = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `https://admin13.uz/api/place-search/name/?page=${
            selected + 1
          }&name=${searchText}`,
          headers: {
            accept: "application/json",
          },
        });
        dispatch(setLoadingState(false));
        console.log(res.data);
        dispatch(setPlaceData(res.data.results));
      } catch (err) {
        dispatch(setLoadingState(false));
        console.log(err);
      }
    };
    fetchData();
  };
  return (
    <div className="pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel={null}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={null}
        renderOnZeroPageCount={null}
        containerClassName="flex w-full justify-between items-center"
        activeClassName="bg-[#2970FF] rounded-[8px] hover:bg-[#2970FF]"
        pageLinkClassName="flex justify-center items-center w-[40px] h-[40px] rounded-[8px] p-[8px]"
      />
    </div>
  );
};

export default Pagination;
