import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import "./app.scss";
import Home from "../home/home";
import Clients from "../clients/clients";
import Features from "../features/features";
import Comments from "../comments/comments";
import Faq from "../faq/faq";
import Footer from "../footer/footer";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Contact from "../contact/contact";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import i18next from "i18next";
import AddPlace from "../add-place/add-place";
import SearchPlace from "../add-place/search-place";
import SearchPlaceItem from "../add-place/search-place-item";
import SearchConnect from "../add-place/search-connect";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["en", "uz", "ru"],
    fallbackLng: "en",
    detection: {
      order: ["htmlTag", "cookie", "localStorage", "subdomain", "path"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/{{lng}}/translation.json",
    },
  });

const App = () => {
  const defLang = localStorage.getItem("lang");

  const [statistic, setStatistic] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("https://admin13.uz/api/stats/");
        if (res.ok) {
          const data = await res.json(); // Parse the response JSON
          setStatistic(data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
    if (pathname === "/uz" || pathname === "/eng" || pathname === "/ru") {
      localStorage.setItem("lang", pathname.split("/")[1] ? pathname.split("/")[1] : "en");
      navigate("/")
    }
    i18next.changeLanguage(defLang ? defLang : "en");
  }, []);

  return (
    <div className="app relative">
      <div className=" top-0 left-0 fixed w-full z-[500] bg-[#0a090c]">
        <Navbar />
      </div>
      <div id="blur-effect" className="">
        <Routes>
          <Route
            path={"/"}
            element={
              <>
                <div className="w-full relative overflow-hidden">
                  <Home statistic={statistic} />
                </div>
                <Clients />
                <Features />
                {/* <div id="comments" className="pt-[88px]">
                  <Comments />
                </div> */}
                <Faq />
              </>
            }
          />
          <Route path="/search-place" element={<SearchPlace/>}/>
          <Route path="/search-connect" element={<SearchConnect/>}/>
          <Route path="/search-place/:id" element={<SearchPlaceItem/>}/>
          <Route path={"/add-place"} element={<AddPlace />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default App;
