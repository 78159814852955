import React, { useEffect, useState } from "react";
import MapContainer from "./map";
import SearchResult from "./search-results";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import YandexMap from "../map";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
import { infoCircle } from "../../imgs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import StepperControl from "../stepper-control";

const PlaceLocation = () => {
  const [open, setOpen] = React.useState(false);
  const [locationError, setLocationError] = useState(false); // Error state for location
  const [infoError, setInfoError] = useState(false); // Error state for info
  const dispatch = useDispatch();
  const { fetchPlace, currentStep } = useSelector((state) => state.events);
  const [searchText, setSearchText] = useState("");
  let w = document.documentElement.clientWidth;

  useEffect(() => {
    if (fetchPlace.latitude && fetchPlace.longitude && fetchPlace.info) {
      dispatch(setStepperCheck(false));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace, dispatch]);

  const validateInputs = () => {
    let isValid = true;

    // Validate if full_address is present (change this depending on your logic)
    if (!fetchPlace.full_address) {
      setLocationError(true);
      isValid = false;
    } else {
      setLocationError(false);
    }

    // Validate if the "info" field is not empty
    if (!fetchPlace.info || fetchPlace.info.trim() === "") {
      setInfoError(true);
      isValid = false;
    } else {
      setInfoError(false);
    }

    return isValid;
  };

  const handleInfoChange = (e) => {
    // Clear the infoError when the user starts typing
    if (infoError) {
      setInfoError(false);
    }
    dispatch(
     setFetchData({
       ...fetchPlace,
       info: e.target.value,
     })
    );
  };

  const handleClick = () => {
    setOpen(!open);
  };

  console.log(infoError, "INFO ERROR");
  console.log(locationError, "LOCATION ERROR");

  return (
   <div>
     <h1 className="text-[29px] font-[600] flex items-center">
       {t("step3")}
       <ClickAwayListener onClickAway={() => setOpen(false)}>
         <div
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
         >
           <Tooltip
            title={t("step3_about")}
            arrow
            placement={w > 640 ? "right" : "top"}
            className="cursor-pointer"
            open={open}
            onClick={handleClick}
           >
             <img
              src={infoCircle}
              alt="info"
              className="w-[20px] h-[20px] ml-[12px] mt-1"
              onClick={() => setOpen(true)}
             />
           </Tooltip>
         </div>
       </ClickAwayListener>
     </h1>
     <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>

     {/* Search Results and Map */}
     <section className="flex flex-col gap-[32px]">
       <SearchResult
        searchText={searchText}
        locationError={locationError}
        setLocationError={setLocationError} // pass setter to reset error
       />
       {/* Error message for location */}
       {locationError && (
        <p className="text-red-400 text-sm">{t("location_required")}</p>
       )}
       <YandexMap />

       {/* Info input field */}
       <div className="max-md:w-full w-[350px] flex flex-col gap-2 mt-[50px]">
         <label className="text-[#CECFD2] text-[14px] font-[500]" htmlFor="text">
           {t("place_info")}
         </label>
         <textarea
          className={`${
           infoError ? "!border-red-400" : "border-[#fdfdfd4d]"
          } max-md:w-full h-[150px] bg-[#1d1d20] border-[1px] border-solid contact-input input-style p-[10px] text-[16px] font-[400] input-form`}
          type="text"
          placeholder={t("step1")}
          onChange={handleInfoChange}
          name="info"
          value={fetchPlace.info}
         />

         {/* Error message for info */}
         {infoError && (
          <p className="text-red-400 text-sm mt-[8px]">{t("info_required")}</p>
         )}
       </div>
     </section>

     {/* Stepper Control */}
     <StepperControl
      currentStep={currentStep}
      handleClick={() => {}}
      validateInputs={validateInputs} // Pass the validation function
     />
   </div>
  );
};

export default PlaceLocation;
