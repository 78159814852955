import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import locationsvg from "./true-location.png";
import {
  YMap,
  YMapComponentsProvider,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapListener,
  YMapControls,
  YMapGeolocationControl,
  YMapZoomControl,
  YMapContainer,
} from "ymap3-components";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData } from "../../reducer/redux";
import axios from "axios";
function YandexMap() {
  const apiKey = "0cce4c39-8879-4e3c-b343-288c3e6adcd0";
  const dispatch = useDispatch();
  const { selectPlace, searchQueryPlace, fetchPlace } = useSelector(
    (state) => state.events
  );
  const [location, setLocation] = useState({
    center: [69.19680255873442, 41.28907061545148], // Default center coordinates (Moscow)
    zoom: 14, // Default zoom level
  });

  const onUpdate = useCallback(({ location, mapInAction }) => {
    if (!mapInAction) {
      setLocation({
        center: location.center,
        zoom: location.zoom,
      });
    }
    // const fetchData = async () => {
    //   try {
    //     const res = await axios.get(
    //       `https://geocode-maps.yandex.ru/1.x/?apikey=0cce4c39-8879-4e3c-b343-288c3e6adcd0&geocode=${location?.center[1]},${location?.center[0]}&format=json`
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // fetchData();
  }, []);
  useEffect(() => {
    const coordinates = searchQueryPlace.find((place, idx) => {
      return place.properties?.name === selectPlace.name;
    });
    if (
      coordinates?.geometry?.coordinates[0] &&
      coordinates?.geometry?.coordinates[1]
    ) {
      setLocation({
        center: [
          coordinates?.geometry?.coordinates[0],
          coordinates?.geometry?.coordinates[1],
        ],
        zoom: 12,
      });
    }
  }, [selectPlace, searchQueryPlace]);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 12,
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setFetchData({
        ...fetchPlace,
        latitude: location?.center[1],
        longitude: location?.center[0],
      })
    );
  }, [location]);
  return (
    <div className="App relative flex justify-center items-center">
      <img
        className="absolute w-[40px] z-10 top-[126px]"
        src={locationsvg}
        alt=""
      />
      <YMapComponentsProvider apiKey={apiKey} lang="en_EN">
        <YMap location={location} mode="vector" theme="dark">
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />
          <YMapListener onUpdate={onUpdate} />
          <YMapControls position="bottom">
            <YMapZoomControl />
          </YMapControls>
          <YMapControls position="bottom right">
            <YMapGeolocationControl />
          </YMapControls>
          <YMapControls position="top left">
            <YMapContainer>
              {/* Additional components can be added here */}
            </YMapContainer>
          </YMapControls>
        </YMap>
      </YMapComponentsProvider>
    </div>
  );
}

export default YandexMap;
