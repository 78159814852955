import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
import { infoCircle } from "../../imgs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import StepperControl from "../stepper-control";


const PlaceTime = () => {
  const weekDays = [
    { id: 1, weekName: t('weekdays.1'), dayOfWeek: 1 },
    { id: 2, weekName: t('weekdays.2'), dayOfWeek: 2 },
    { id: 3, weekName: t('weekdays.3'), dayOfWeek: 3 },
    { id: 4, weekName: t('weekdays.4'), dayOfWeek: 4 },
    { id: 5, weekName: t('weekdays.5'), dayOfWeek: 5 },
    { id: 6, weekName: t('weekdays.6'), dayOfWeek: 6 },
    { id: 7, weekName: t('weekdays.7'), dayOfWeek: 7 }
  ];
  let w = document.documentElement.clientWidth;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { fetchPlace, currentStep } = useSelector((state) => state.events);
  const [errorMessage, setErrorMessage] = useState(false); // Error message for validation
  const [formData, setFormData] = useState({
    name: "",
    imageUrl: "",
    phone: "",
    descriptionRU: "",
    descriptionUz: "",
    workDayList: fetchPlace.work_days ? fetchPlace.work_days : [],
    isNotDaysOff: false,
  });

  // Handle changes in working hours
  const handleChangeData = (e, idx) => {
    let value = e.target.value.replace(/[^0-9:]/g, "");
    value = value.slice(0, 5);
    const name = e.target.name;
    const formattedValue = [];

    if (/^[\d:]+$/.test(value)) {
      for (let i = 0; i < value.length; i++) {
        if (i === 2 && value[i] !== ":") {
          formattedValue.push(":");
        }
        formattedValue.push(value[i]);
      }
    }
    const finalValue = formattedValue.join("");
    setFormData((prevState) => ({
      ...prevState,
      workDayList: prevState.workDayList.map((day, index) =>
       day.dayOfWeek === idx ? { ...day, [name]: finalValue } : day
      ),
    }));
  };

  // Handle switching of workdays
  const handleChangeWeek = (index) => {
    const dayOfWeekExists = formData.workDayList.some(
     (day) => day.dayOfWeek === index
    );

    if (!dayOfWeekExists) {
      const updatedWorkDayList = [
        ...formData.workDayList,
        {
          dayOfWeek: index,
          startTime: "09:00",
          endTime: "18:00",
        },
      ];
      setFormData((prevState) => ({
        ...prevState,
        workDayList: updatedWorkDayList,
      }));
    } else {
      const updatedWorkDayList = formData.workDayList.filter(
       (day) => day.dayOfWeek !== index
      );
      setFormData((prevState) => ({
        ...prevState,
        workDayList: updatedWorkDayList,
      }));
    }
  };

  // Handle 'Dam olish kunlarisiz' (Without days off) switch
  const handleStatus = () => {
    setFormData((prevFormData) => {
      const updatedIsNotDaysOff = !prevFormData.isNotDaysOff;

      const weekDays = [
        { dayOfWeek: 1, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 2, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 3, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 4, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 5, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 6, startTime: "09:00", endTime: "18:00" },
        { dayOfWeek: 7, startTime: "09:00", endTime: "18:00" },
      ];

      return {
        ...prevFormData,
        isNotDaysOff: updatedIsNotDaysOff,
        workDayList: updatedIsNotDaysOff ? weekDays : [],
      };
    });
  };

  // Validate if at least one work day or 'Dam olish kunlarisiz' is selected
  const validateInputs = () => {
    if (formData.workDayList.length === 0) {
      setErrorMessage(true); // Show error if no days are selected
      return false;
    } else {
      setErrorMessage(false); // No error, validation passed
      return true;
    }
  };

  // Update Redux state whenever workDayList changes
  useEffect(() => {
    dispatch(
     setFetchData({
       ...fetchPlace,
       work_days: formData.workDayList,
     })
    );
  }, [formData.workDayList]);

  useEffect(() => {
    if (fetchPlace.work_days.length > 0) {
      dispatch(setStepperCheck(false)); // Allow next step
    } else {
      dispatch(setStepperCheck(true)); // Prevent next step
    }
  }, [fetchPlace]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
   <div>
     <h1 className="text-[29px] font-[600] flex items-center">
       {t("step5")}
       <ClickAwayListener onClickAway={() => setOpen(false)}>
         <div
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
         >
           <Tooltip
            title={t("step5_about")}
            arrow
            placement={w > 640 ? "right" : "top"}
            className="cursor-pointer"
            open={open}
            onClick={handleClick}
           >
             <img
              src={infoCircle}
              alt="info"
              className="w-[20px] h-[20px] ml-[12px] mt-1"
              onClick={() => setOpen(true)}
             />
           </Tooltip>
         </div>
       </ClickAwayListener>
     </h1>
     <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
     <h1 className="text-[#fdfdfd] text-[20px] font-[500] mt-[40px] mb-[20px]">
       {t("days_of_week")}
     </h1>
     <div className="flex flex-col gap-[12px]">
       {weekDays.map((item, idx) => (
        <main
         key={idx}
         className="w-full grid grid-cols-3 max-sm:grid-cols-2 gap-[48px]"
        >
          <section className="flex items-center gap-[12px] w-[150px]">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
               type="checkbox"
               className="sr-only peer"
               checked={formData.workDayList.some(
                (c) => c.dayOfWeek === item.id
               )}
               onChange={() => handleChangeWeek(item.dayOfWeek)}
              />

              <div className="relative w-[36px] h-[20px] bg-[#333] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all  peer-checked:bg-[#2970FF]"></div>
            </label>
            <h1 className="text-[16px] font-[500]">{item.weekName}</h1>
          </section>
          <section className="flex items-center justify-start gap-[12px]">
            <input
             disabled={
               formData?.workDayList.find(
                (c) => c.dayOfWeek === item.dayOfWeek
               )
                ? false
                : true
             }
             className={`text-[16px] max-w-[120px] max-sm:w-full border-[#333741] max-sm:px-[8px] text-center px-[16px] rounded-[8px] bg-[#1d1d20] outline-none text-h1 focus:shadow-btn border-[1px] border-solid flex py-[8px] `}
             type="text"
             placeholder="09:00"
             name="startTime"
             value={
              formData.workDayList.find((c) => c.dayOfWeek === item.id)
               ?.startTime || "" // Ensure it defaults to an empty string if undefined
             }
             onChange={(e) => handleChangeData(e, item.dayOfWeek)}
            />
            -
            <input
             disabled={
               formData?.workDayList.find((c) => c.dayOfWeek === item.id)
                ? false
                : true
             }
             className={`text-[16px] max-w-[120px] max-sm:w-full max-sm:px-[8px] text-center px-[16px] border-[#333741] rounded-[8px] bg-[#1d1d20] outline-none text-h1 focus:shadow-btn border-[1px] border-solid flex py-[8px]`}
             type="text"
             placeholder="09:00"
             value={
              formData.workDayList.find((c) => c.dayOfWeek === item.id)
               ?.endTime || "" // Ensure it defaults to an empty string if undefined
             }
             name="endTime"
             onChange={(e) => handleChangeData(e, item.dayOfWeek)}
            />
          </section>
        </main>
       ))}
       <main className="w-full flex items-center gap-[48px] mt-[10px]">
         <section className="flex items-center gap-[12px]">
           <label className="relative inline-flex items-center cursor-pointer">
             <input
              type="checkbox"
              checked={formData?.isNotDaysOff}
              onChange={handleStatus}
              className="sr-only peer"
             />
             <div className="relative w-[36px] h-[20px] bg-[#333] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all  peer-checked:bg-[#2970FF]"></div>
           </label>
           <h1>{t("no_days_off")}</h1>
         </section>
       </main>

       {/* Show error message if validation fails */}
       {errorMessage && (
        <p className="text-red-400 text-sm mt-[8px]">
          {t("error_working_days_required")}
        </p>
       )}

       {/* Stepper Control */}
       <StepperControl
        currentStep={currentStep}
        handleClick={() => {}}
        validateInputs={validateInputs} // Pass the validation function
       />
     </div>
   </div>
  );
};

export default PlaceTime;
