import React, {useState} from "react";
import {next, prev} from "./imgs";
import {useDispatch, useSelector} from "react-redux";
import {clearFetchPlace, setPlaceData, setSearchText, setStep} from "../../reducer/redux";
import {t} from "i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import SuccessModal from "./success-modal";

const StepperControl = ({handleClick, validateInputs}) => {
 const {stepperNext, validInput, currentStep, fetchPlace} = useSelector(state => state.events);
 const dispatch = useDispatch();
 const [success,setSuccess]=useState(false)
 const navigate = useNavigate()
 const handleSendSuccess=()=>{
  setSuccess(!success)
 }
 const steps = [
  t("step1"),
  t("step2"),
  t("step3"),
  t("step4"),
  t("step5"),
  t("step6"),
  // t("step7"),
  // t("step8"),
  t("step9"),
 ];
 const handleBackMenu=()=>{
  navigate('/')
  dispatch(setSearchText(""))
  dispatch(setPlaceData([]))
  dispatch(setStep(1))
  const body = document.querySelector(".app");
  body.classList.remove("blur-effect");
  setSuccess(false)
 }

 // Handle button click and trigger validation
 const handleNextClick = (e) => {
  const isValid = !!validateInputs();
  if (currentStep === steps.length && isValid) {
   const formData = new FormData();
   formData.append("name", fetchPlace?.name);
   formData.append("phone", fetchPlace?.phone);
   formData.append("phone2", fetchPlace?.phone2);
   formData.append("image", fetchPlace?.image);
   formData.append("latitude", fetchPlace?.latitude);
   formData.append("longitude", fetchPlace?.longitude);
   formData.append("place_type", JSON.stringify(fetchPlace?.place_type));
   formData.append("work_start_time", fetchPlace?.work_start_time);
   formData.append("work_end_time", fetchPlace?.work_end_time);
   formData.append("work_days", JSON.stringify(fetchPlace?.work_days));
   formData.append("website", fetchPlace?.website);
   formData.append("instagram", fetchPlace?.instagram);
   formData.append("telegram", fetchPlace?.telegram);
   formData.append("telegram_bot", fetchPlace?.telegram_bot);
   formData.append("facebook", fetchPlace?.facebook);
   formData.append("twitter", fetchPlace?.twitter);
   formData.append("youtube", fetchPlace?.youtube);
   formData.append("info", fetchPlace?.info);
   formData.append("full_address", fetchPlace?.full_address);
   formData.append("timezone", fetchPlace?.timezone);
   formData.append("user_name", fetchPlace?.user_name);
   formData.append("status", fetchPlace?.status);
   formData.append("created_at", fetchPlace?.created_at);
   formData.append("user_name", fetchPlace?.applicantName);
   formData.append("user_phone", fetchPlace?.applicantPhone);
   const fetchData = async () => {
    try {
     const res = await axios({
      method: "POST",
      headers: {
       "Content-Type": "multipart/form-data"
      },
      url: "https://admin13.uz/api/new-place/",
      data: formData
     })
     if (!!res.data) {
      handleSendSuccess();
      dispatch(clearFetchPlace());  // Clear the Redux state
     }
     console.log(res)
    } catch (error) {
     console.log(error)
    }
   }
   fetchData()
  } else if (isValid) {
   handleClick("next");
   dispatch(setStep(currentStep + 1))
  }
  e.preventDefault();
  window.scrollTo({ top: 0, behavior: 'smooth' });
 };


 return (
  <section
   className="w-full max-sm:bg-[#0A090C] max-sm:px-[20px] pb-[32px] pt-[12px] max-sm:z-10 max-sm:border-t border-[#1F242F] right-0 max-sm:fixed max-sm:bottom-0 max-sm:grid grid-cols-2 mt-[10px] flex justify-end items-start gap-[24px]">
   {!(currentStep === 1) ? (
    <button
     onClick={() => {
      handleClick()
      dispatch(setStep(currentStep - 1))
      window.scrollTo({ top: 0, behavior: 'smooth' })
     }}
     className={`px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] btn-prev `}
    >
     <img src={prev} alt=""/>
     <h1 className="text-[14px] max-sm:text-[16px] font-[500]">Orqaga</h1>
    </button>
   ) : (
    <div></div>
   )}
   <button
    // disabled={stepperNext}
    onClick={e => handleNextClick(e)}
    className={`${
     stepperNext && "opacity-[0.7]"
    } px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] bg-[#2970FF] rounded-[8px]`}
   >
    <h1 className="text-[14px] max-sm:text-[16px] font-[500]">
     {currentStep === steps.length ? "Yuborish" : "Keyingisi"}
    </h1>
    <img src={next} alt=""/>
   </button>
   <SuccessModal handleClose={handleSendSuccess} handleBackMenu={handleBackMenu} isOpen={success}/>
  </section>
 );
};

export default StepperControl;
