import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import {t} from "i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Tooltip} from "@mui/material";
import {infoCircle} from "../../imgs";
import StepperControl from "../stepper-control";

const Links = () => {
  const [open, setOpen] = React.useState(false);
  const { fetchPlace, currentStep } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  let w = document.documentElement.clientWidth;
  let h = document.documentElement.clientHeight;
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setFetchData({
        ...fetchPlace,
        [name]: value,
      })
    );
  };
  useEffect(() => {
    dispatch(setStepperCheck(false));
  }, [fetchPlace]);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      <h1 className="text-[29px] font-[600] flex items-center">
        {t("step6")}
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div onMouseOver={() => setOpen(true)}
               onMouseLeave={() => setOpen(false)}>
            <Tooltip
             title={t("step6_about")}
             arrow
             placement={w > 640 ? "right" : "top"}
             className="cursor-pointer"
             open={open}
             onClick={handleClick}
            >
              <img src={infoCircle} alt="info" className="w-[20px] h-[20px] ml-[12px] mt-1"
                   onClick={() => setOpen(true)}/>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </h1>
      <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
      <form action="" className="flex flex-col gap-[32px] mt-[24px]">
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
           className="text-[#CECFD2] text-[14px] font-[500]"
           htmlFor="text"
          >{t("link_for_web")}</label>
          <input
           className={`${
            false ? " border-red-400" : "border-[#fdfdfd4d]"
           } border-[1px] border-solid contact-input input-style  py-[10px] px-[14px] bg-transparent text-[16px] font-[400] input-form`}
           type="text"
           value={fetchPlace.website}
            placeholder={"www.example.com"}
            name="website"
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >{t("link_for_instagram")}</label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  py-[10px] px-[14px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.instagram.com"}
            value={fetchPlace.instagram}
            name="instagram"
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >{t("link_for_telegram")}</label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  py-[10px] px-[14px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.teleram.me"}
            name="telegram"
            value={fetchPlace.telegram}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >{t("link_for_telegram")}</label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  py-[10px] px-[14px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.teleram.bot"}
            name="telegram_bot"
            value={fetchPlace.telegram_bot}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
        <div className="max-sm:w-full w-[350px] flex flex-col gap-2">
          <label
            className="text-[#CECFD2] text-[14px] font-[500]"
            htmlFor="text"
          >{t("link_for_youtube")}</label>
          <input
            className={`${
              false ? " border-red-400" : "border-[#fdfdfd4d]"
            } border-[1px] border-solid contact-input input-style  py-[10px] px-[14px] bg-transparent text-[16px] font-[400] input-form`}
            type="text"
            placeholder={"www.youtube.com"}
            name="youtube"
            value={fetchPlace.youtube}
            onChange={handleChange}
          />
          {/* <label className="text-[red]" htmlFor="text">{errorMessage?.full_name && t("error_msg")}</label> */}
        </div>
      </form>
      <StepperControl currentStep={currentStep} handleClick={() => {}} validateInputs={() => true} />
    </div>
  );
};

export default Links;
