import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import {t} from "i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Tooltip} from "@mui/material";
import {infoCircle} from "../../imgs";
function TimeZone() {
 let w = document.documentElement.clientWidth;
 const [open, setOpen] = React.useState(false);
  const { fetchPlace } = useSelector((state) => state.events);
  const [selectedTimezone, setSelectedTimezone] = useState(
    fetchPlace.timeZone
      ? fetchPlace.timeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStepperCheck(false));
  }, [fetchPlace]);
  useEffect(() => {
    dispatch(
      setFetchData({
        ...fetchPlace,
        timezone: selectedTimezone,
      })
    );
  }, [selectedTimezone]);

 const handleClick = () => {
  if (open) {
   setOpen(false);
  } else {
   setOpen(true);
  }
 };

  return (
    <div>
     <h1 className="text-[29px] font-[600] flex items-center">
      {t("step7")}
      <ClickAwayListener onClickAway={() => setOpen(false)}>
       <div onMouseOver={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}>
        <Tooltip
         title={t("step7_about")}
         arrow
         placement={w > 640 ? "right" : "top"}
         className="cursor-pointer"
         open={open}
         onClick={handleClick}
        >
         <img src={infoCircle} alt="info" className="w-[20px] h-[20px] ml-[12px] mt-1"
              onClick={() => setOpen(true)}/>
        </Tooltip>
       </div>
      </ClickAwayListener>
     </h1>
     <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
     <div className="select-wrapper max-w-[400px] bg-black">
      <TimezoneSelect
       className="react-select-container"
       classNamePrefix="react-select"
       value={selectedTimezone}
       onChange={setSelectedTimezone}
      />
     </div>
    </div>
  );
}

export default TimeZone;
