import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFetchData, setStepperCheck, setValidInput} from "../../../reducer/redux";
import {infoCircle} from "../../imgs";
import {t} from "i18next";
import {Tooltip} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import StepperControl from "../stepper-control";

const PlaceAbout = () => {
 const [open, setOpen] = React.useState(false);
 const [errors, setErrors] = useState({ name: "", phone: "" });  // Error state for inputs
 const [touched, setTouched] = useState({ name: false, phone: false });  // Track if inputs are touched
 const dispatch = useDispatch();
 const {fetchPlace, currentStep} = useSelector((state) => state.events);

 let w = document.documentElement.clientWidth;

 // Input validation function for name
 const validateName = (name) => {
  if (name.length === 0) {
   return t("name_required");  // Return an error message if name is empty
  }
  return "";
 };
 // Input validation function for phone
 const validatePhone = (phone) => {
  // Strip all non-numeric characters except the "+"
  const cleanedPhone = phone.replace(/[^\d+]/g, '');

  // Expecting a phone pattern like +998 followed by 9 digits
  const phoneRegex = /^\+998\d{9}$/;

  if (!phoneRegex.test(cleanedPhone)) {
   return t("phone_invalid");  // Return error message if phone is invalid
  }
  return "";
 };

 const handleChange = (e) => {
  const { name, value } = e.target;
  let newErrors = { ...errors };

  // Validate name and phone on input change
  if (name === "name") {
   newErrors.name = validateName(value);
  }
  if (name === "phone") {
   newErrors.phone = validatePhone(value);
  }

  setErrors(newErrors);

  // Dispatch valid data if no errors
  dispatch(
   setFetchData({
    ...fetchPlace,
    [name]: value,
   })
  );
 };

 // Handle focus event
 const handleFocus = (name) => {
  setTouched({
   ...touched,
   [name]: true,
  });
 };

 const validateInputs = () => {
  let isValid = true;

  // Check if name is valid
  if (fetchPlace.name.trim() === "") {
   setErrors((prev) => ({ ...prev, name: t("name_required") }));
   isValid = false;
  }

  const cleanedPhone = fetchPlace.phone.replace(/[^\d+]/g, '');

  // Expecting a phone pattern like +998 followed by 9 digits
  const phoneRegex = /^\+998\d{9}$/;

  // Check if phone is valid
  if (!phoneRegex.test(cleanedPhone)) {
   setErrors((prev) => ({ ...prev, phone: t("phone_invalid") }));
   isValid = false;
  }

  // Mark inputs as touched if they aren't already
  setTouched({ name: true, phone: true });
  return isValid;
 };

 // Handle blur event
 const handleBlur = (name) => {
  if (fetchPlace[name].length === 0) {
   setErrors({
    ...errors,
    [name]: `${name}_required`, // Add error message when input is empty on blur
   });
  }
 };

 useEffect(() => {
  if (fetchPlace.phone.length == 13 && fetchPlace.name.length > 0) {
   dispatch(setStepperCheck(false));
  } else {
   dispatch(setStepperCheck(true));
  }
 }, [fetchPlace]);

 useEffect(() => {
  const year = new Date().getFullYear();
  const day = new Date().getDate();
  const month = new Date().getMonth();
  dispatch(setFetchData({
   ...fetchPlace,
   created_at: `${year}-${month + 1}-${day}`,
  }));
 }, []);

 const handleClick = () => {
  if (open) {
   setOpen(false);
  } else {
   setOpen(true);
  }
 };

 return (
  <main>
   <div className="py-[16px] mb-[24px]">
    <h1 className="text-[29px] font-[600]">{t("step1")}</h1>
    <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
    <form action="" className="flex flex-col gap-[32px] mt-[24px]">
     <div className="max-md:w-full w-[350px] flex flex-col gap-2">
      <label
       className="text-[#CECFD2] text-[14px] font-[500]"
       htmlFor="text"
      >
       {t("place_name")}
      </label>
      <div className="relative">
       <input
        className={`${
         (touched.name && fetchPlace.name.length === 0) || errors.name ? "!border-red-400" : "border-[#fdfdfd]"
        } border-[1px] border-solid contact-input input-style p-[10px] bg-transparent text-[16px] font-[400] input-form w-full`}
        type="text"
        onChange={handleChange}
        onFocus={() => handleFocus("name")}
        onBlur={() => handleBlur("name")}
        placeholder={"Mini market"}
        value={fetchPlace.name}
        name="name"
       />
       {errors.name && <span className="text-red-400 text-sm">{errors.name}</span>} {/* Show error */}
       <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div onMouseOver={() => setOpen(true)}
             onMouseLeave={() => setOpen(false)}>
         <Tooltip title={t("step1_about")}
                  arrow
                  placement={w > 640 ? "right" : "top"}
                  className="cursor-pointer"
                  open={open}
                  onClick={handleClick}
         >
          <img src={infoCircle} alt="info" className="w-[15px] h-[15px] absolute right-5 top-4"/>
         </Tooltip>
        </div>
       </ClickAwayListener>
      </div>
     </div>

     <div className="max-md:w-full w-[350px] flex flex-col gap-2">
      <PhoneNumber phone={fetchPlace.phone} phone2={false} error={errors.phone} setErrors={setErrors} touched={touched.phone} setTouched={setTouched} />
     </div>
     <div className="max-md:w-full w-[350px] flex flex-col gap-2">
      <PhoneNumber phone={fetchPlace.phone2} phone2={true} />
     </div>
     <StepperControl currentStep={currentStep} handleClick={() => {}} validateInputs={validateInputs} />
    </form>
   </div>
  </main>
 );
};

export default PlaceAbout;

const PhoneNumber = ({ error, phone, phone2, setErrors, touched, setTouched }) => {
 const { fetchPlace } = useSelector((state) => state.events);
 const [phoneNumber, setPhoneNumber] = useState({ phone: phone ? phone : "" });
 const dispatch = useDispatch();

 const handleInputChange = (e) => {
  let inputValue = e.target.value.replace(/[^0-9]/g, "");
  inputValue = inputValue.slice(0, 12); // Keep it within 12 digits

  const formattedValue = [];
  for (let i = 0; i < inputValue.length; i++) {
   if (i === 0) {
    formattedValue.push("+");
   }
   if (i === 3) {
    formattedValue.push(" (");
   }
   if (i === 5) {
    formattedValue.push(") ");
   }
   if (i === 8 || i === 10) {
    formattedValue.push(" ");
   }
   formattedValue.push(inputValue[i]);
  }

  const finalValue = formattedValue.join("");
  const { name } = e.target;

  setPhoneNumber({
   ...phoneNumber,
   [name]: finalValue,
  });

  // Validate phone number based on number of digits, not formatted string length
  if (!phone2) {
   setErrors((prevErrors) => ({
    ...prevErrors,
    phone: inputValue.length < 12 ? t("phone_invalid") : "", // Expect 12 digits (not the formatted string length)
   }));
  }
 };

 const handleInputFocus = () => {
  if (!phoneNumber.phone) {
   setPhoneNumber({
    ...phoneNumber,
    phone: "+998",
   });
  }
 };

 const handleBlur = () => {
  if (!phone2) {
   setTouched(true);  // Mark input as touched on blur
  }
 };

 useEffect(() => {
  const phone = phoneNumber.phone;
  const phoneNumberWithPlus = phone.replace(/[^0-9+]/g, "");
  dispatch(
   setFetchData(phone2 ? {
    ...fetchPlace,
    phone2: phoneNumberWithPlus,
   } : {
    ...fetchPlace,
    phone: phoneNumberWithPlus,
   })
  );
 }, [phoneNumber, dispatch]);

 return (
  <>
   <input
    className={`${
     (touched && phoneNumber.phone.length === 0) || error ? "!border-red-400" : "border-[#fdfdfd]"
    } border-[1px] border-solid contact-input input-style p-[10px] bg-transparent text-[16px] font-[400] input-form`}
    placeholder="+998 (88) 123 45 67"
    name="phone"
    value={phoneNumber.phone}
    onChange={handleInputChange}
    onFocus={handleInputFocus}
    onBlur={handleBlur}
   />
   {error && <span className="text-red-400 text-sm">{error}</span>}
  </>
 );
};
