import React, { useEffect, useState } from "react";
import {
  calendar,
  delivery,
  edit,
  location,
  next,
  phone,
  placeicon,
  placeinfo,
  placetime,
  prev,
  telegram,
  web,
} from "./imgs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { setEditPlace } from "../../reducer/redux";
import { useDispatch } from "react-redux";
const weekDays = [
  { id: 1, weekName: "Dushanba", dayOfWeek: 1 },
  { id: 2, weekName: "Seshanba", dayOfWeek: 2 },
  { id: 3, weekName: "Chorshanba", dayOfWeek: 3 },
  { id: 4, weekName: "Payshanba", dayOfWeek: 4 },
  { id: 5, weekName: "Juma", dayOfWeek: 5 },
  { id: 6, weekName: "Shanba", dayOfWeek: 6 },
  { id: 7, weekName: "Yakshanba", dayOfWeek: 7 },
];
const SearchPlaceItem = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      id: 1,
      name: "Yetkazib berish xizmati",
      icon: delivery,
      check: false,
    },
    {
      id: 2,
      name: "Joy band qilish xizmati",
      icon: calendar,
      check: false,
    },
  ]);
  const [placeItem, setPlaceItem] = useState({});

  const handleCheck = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, check: !item.check } : item
    );
    setData(updatedData);
  };

  const handleEdit = () => {
    dispatch(setEditPlace(placeItem?.id));
    navigate(`/add-place`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `https://admin13.uz/api/place/${id}/`,
          headers: {
            accept: "application/json",
          },
        });
        setPlaceItem(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);
  console.log(placeItem);

  return (
    <main className="max-lg:w-11/12 w-10/12  mt-[144px] max-w-[1440px] mx-auto flex flex-col justify-center items-center gap-[24px]">
      <section className="flex flex-col gap-[8px] max-lg:w-11/12 w-[70%]">
        <h1 className="text-[#fdfdfd] font-[600] text-[30px]">
          Joy haqida ma’lumotlar
        </h1>
      </section>
      <div className="max-lg:w-11/12 w-[70%] h-[1px] bg-[#333]"></div>
      <section className="max-lg:w-11/12 w-[70%] step-content sm:p-[56px] flex flex-col gap-[8px]">
        <h1 className="text-[18px] font-[500] text-[#F5F5F6]">
          {placeItem.name}
        </h1>
        <div className="max-sm:hidden w-full h-[1px] bg-[#333]"></div>
        <section className="grid grid-cols-3 ">
          <article className="col-span-2">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="flex flex-col gap-[8px] cursor-pointer mt-[16px] hover:bg-[#">
                <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                  Joy nomi
                </h1>
                <div className="flex gap-[8px]">
                  <img src={placeicon} alt="" />
                  <p className="text-[14px] font-[400] text-[#94969C]">
                    {placeItem.name ? placeItem.name : "Manzil mavjud emas!!!"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[8px] cursor-pointer mt-[16px] hover:bg-[#">
                <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                  Telefon raqami
                </h1>
                <div className="flex gap-[8px]">
                  <img src={phone} alt="" />
                  <p className="text-[14px] font-[400] text-[#94969C]">
                    {placeItem.phone
                      ? placeItem.phone
                      : "Manzil mavjud emas!!!"}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-[8px] cursor-pointer mt-[16px] hover:bg-[#">
                <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                  Joy rasmi
                </h1>
                <div className="flex gap-[8px]">
                  {placeItem.photo_url ? (
                    <img
                      className="rounded-[12px] w-[120px] h-[100px] object-cover"
                      src={placeItem.photo_url}
                      alt=""
                    />
                  ) : (
                    <p className="text-[14px] font-[400] text-red-400">
                      Joy rasmi mavjud emas!!!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </article>
          <div className="flex justify-end items-start">
            <button
              onClick={handleEdit}
              className="px-[14px] py-[10px] rounded-[8px] border-[#2E90FA] border-[1px] border-solid text-[#2E90FA] flex justify-start items-center gap-[6px]"
            >
              <img src={edit} alt="" />
              Tahrilash
            </button>
          </div>
        </section>
        <main className="grid grid-cols-2 max-lg:grid-cols-1 sm:mt-[12px] max-lg:gap-[32px]">
          <article className="flex flex-col gap-[8px]">
            <div className="flex flex-col gap-[8px] cursor-pointer mt-[16px] hover:bg-[#">
              <h1 className="text-[14px] font-[500] text-[#CECFD2]">Manzil</h1>
              <div className="flex gap-[8px]">
                <img src={location} alt="" />
                {placeItem.full_address ? (
                  <p className="text-[14px] font-[400] text-[#94969C]">
                    {placeItem.full_address}
                  </p>
                ) : (
                  <p className="text-[14px] font-[400] text-red-400">
                    Manzil ma'lumotlari mavjud emas!!!
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[12px] cursor-pointer mt-[16px] hover:bg-[#">
              <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                Joy haqida ma’lumotlar{" "}
              </h1>
              <div className="flex gap-[8px]">
                <img src={placeinfo} alt="" />
                {placeItem.info ? (
                  <p className="text-[14px] font-[400] text-[#CECFD2]"></p>
                ) : (
                  <p className="text-[14px] font-[400] text-red-400">
                    Joy haqida ma'lumot mavjud emas!!!
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[12px] cursor-pointer mt-[16px] hover:bg-[#">
              <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                Ishlash vaqtlari{" "}
              </h1>
              {placeItem.work_days &&
              placeItem.work_days.length > 0 &&
              placeItem.work_days.find((c) => c.dayOfWeek) ? (
                <>
                  {placeItem.work_days.map((item, idx) => (
                    <div key={idx}>
                      <div>
                        {/* Assuming 'weekDays' is defined and contains day names */}
                        {weekDays &&
                          weekDays.find((c) => c.dayOfWeek === item?.dayOfWeek)
                            ?.weekName}
                      </div>
                      <div className="text-[#2970FF] font-[400] text-[14px] flex gap-2">
                        <p>{item?.startTime}</p>-<p>{item?.endTime}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-[14px] font-[400] text-red-400">
                  Ishlash vaqtlari mavjud emas yoki xato ma'lumot kiritilgan
                </p>
              )}
            </div>

            <div className="flex flex-col gap-[12px] cursor-pointer mt-[16px] hover:bg-[#">
              <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                Ijtimoiy tarmoqlar
              </h1>
              {placeItem.website && placeItem.telegram ? (
                <article className="flex gap-[16px] max-sm:flex-col">
                  {placeItem.telegram && (
                    <div className="flex gap-[8px]">
                      <img src={telegram} alt="" />
                      <p className="text-[14px] font-[400] text-[#94969C]">
                        {placeItem.telegram}
                      </p>
                    </div>
                  )}
                  {placeItem.website && (
                    <div className="flex gap-[8px]">
                      <img src={web} alt="" />
                      <p className="text-[14px] font-[400] text-[#94969C]">
                        www.ibroximbek.uz
                      </p>
                    </div>
                  )}
                </article>
              ) : (
                <div className="flex gap-2 items-center text-[12px] font-[400] text-red-300">
                  <img src={web} alt="" />

                  <p className="text-[14px] font-[400] text-red-400">
                    Ijtimoiy tarmoqlar mavjud emas!!!
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[12px] cursor-pointer mt-[16px] hover:bg-[#">
              <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                Vaqt mintaqasi{" "}
              </h1>
              {placeItem.timezone ? (
                <div className="flex gap-[8px]">
                  <img src={placetime} alt="" />
                  <p className="text-[14px] font-[400] text-[#94969C]">
                    {placeItem?.timezone}
                  </p>
                </div>
              ) : (
                <div className="flex gap-[8px]">
                  <img src={placetime} alt="" />
                  <p className="text-[14px] font-[400] text-[#94969C]">
                    Tashkent (GMT +5){" "}
                  </p>
                </div>
              )}
            </div>
          </article>
        </main>
        {/* section1 */}
        <h1 className="lg:mt-[56px] max-lg:mt-[32px] text-[18px] font-[500] text-[#F5F5F6]">
          Xizmat qo’shish
        </h1>
        <div className="w-full h-[1px] bg-[#333] sm:my-[24px] my-[12px]"></div>
        <section className="lg:flex grid grid-cols-2 max-sm:grid-cols-1 items-start justify-start gap-[32px]">
          {data.map((item, idx) => (
            <div
              onClick={() => handleCheck(item.id)}
              key={idx}
              className={`${
                item.check ? " border-[#2970FF]" : "border-[#26272B]"
              } border-[2px] rounded-[12px] gap-[8px] bg-[#26272B] h-[140px] lg:w-[240px] flex items-center justify-center flex-col`}
            >
              <img src={item.icon} alt="" />
              <h1 className="text-[14px] font-[500] text-white">{item.name}</h1>
            </div>
          ))}
        </section>
        <section className="w-full max-sm:bg-[#0A090C] px-[20px] pb-[32px] pt-[12px] max-sm:z-10 border-t border-[#1F242F] right-0 max-sm:fixed max-sm:bottom-0 max-sm:grid grid-cols-2 flex justify-start items-start gap-[24px]">
          <button
            onClick={() => navigate(-1)}
            className={`max-sm:w-full px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] btn-prev `}
          >
            <img src={prev} alt="" />
            <h1 className="text-[14px] max-sm:text-[16px] font-[500]">
              Orqaga
            </h1>
          </button>
          <button
            onClick={() => navigate("/search-connect")}
            className="px-[14px] py-[10px] flex items-center max-sm:justify-center gap-[6px] bg-[#2970FF] rounded-[8px]"
          >
            <h1 className="text-[14px] max-sm:text-[16px] font-[500]">
              Keyingisi
            </h1>
            <img src={next} alt="" />
          </button>
        </section>
      </section>
    </main>
  );
};

export default SearchPlaceItem;
