import React, { useEffect, useRef, useState } from "react";
import { check } from "./imgs";

const Stepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newStep = [...steps];
    let count = 0;
    while (count < newStep.length) {
      if (count === stepNumber) {
        newStep[count] = {
          ...newStep[count],
          heighlighted: true,
          selected: false,
          completed: false,
          active: true,
        };
        count++;
      } else if (count < stepNumber) {
        newStep[count] = {
          ...newStep[count],
          heighlighted: false,
          selected: true,
          completed: true,
          active: false,
        };
        count++;
      } else {
        newStep[count] = {
          ...newStep[count],
          heighlighted: false,
          selected: false,
          completed: false,
          active: false,
        };
        count++;
      }
    }
    return newStep;
  };

  useEffect(() => {
    const stepState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          active: index === 0 ? true : false,
          completed: false,
          heighlighted: false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);
  const displaySteps = newStep?.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? `w-full flex justify-between items-center max-sm:mr-[7px]`
            : "flex justify-center items-center"
        }
      >
        <div className="relative flex flex-col items-center text-[#475467] font-[600] text-[18px] ">
          <div
            className={`max-sm:hidden rounded-full flex flex-col items-center justify-center w-[32px] h-[32px] ${
              step.heighlighted
                ? "bg-[#2970FF] text-white"
                : step.completed
                ? "bg-[#10B981] text-[#475467]"
                : "bg-[#98A2B3] text-[#475467]"
            }`}
          >
            {step.completed ? <img src={check} alt="img" /> : index + 1}
          </div>
          {step.heighlighted && (
            <div className="max-sm:hidden absolute top-0 text-center mt-[36px] w-32 font-[500] text-[#fff]">
              {step.description}
            </div>
          )}
        </div>
        <div
          className={`${
            !step.completed
              ? "sm:border-[#98A2B3] sm:border-dashed"
              : "sm:border-[#10B981]"
          }
          ${
            step.heighlighted
              ? "max-sm:border-[#2970FF] max-sm:text-white"
              : step.completed
              ? "max-sm:border-[#10B981] max-sm:text-[#475467]"
              : "max-sm:border-[#98A2B3] max-sm:text-[#475467]"
          }
           border-t-[2px] flex-auto max-sm:border-t-[4px] rounded-[6px]`}
        ></div>
      </div>
    );
  });

  return (
    <div className="sm:mx-4 max-sm:py-4 sm:p-4 flex justify-between items-center sm:mb-[64px]">
      {displaySteps}
    </div>
  );
};

export default Stepper;
