import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { close } from "./imgs";
import { empty, location, phone, plus, search } from "./imgs";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingState,
  setPlaceData,
  setSearchText,
} from "../../reducer/redux";
import Loader from "../loader/loader";
import Pagination from "./pagination/pagination";
const SearchPlace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchText, place, countPlace, loading } = useSelector(
    (state) => state.events
  );
  const [active, setActive] = useState(searchText ? true : false);
  const [error, setError] = useState("");
  const [countPlaces, setCountPlaces] = useState(null);
  const handleSearch = (e) => {
    setError("");
    dispatch(setSearchText(e.target.value));
  };

  const handleDelete = () => {
    dispatch(setSearchText(""));
    dispatch(setPlaceData([]));
    setActive(false);
  };
  const handleAddPlace = () => {
    navigate("/add-place");
  };

  const handleSubmit = () => {
    setActive(true);
    const fetchData = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `https://admin13.uz/api/place-search/name/?page=1&name=${searchText}`,
          headers: {
            accept: "application/json",
          },
        });
        dispatch(setLoadingState(false));

        console.log(res.data);
        setCountPlaces(res.data.count);
        dispatch(setPlaceData(res.data.results));
      } catch (err) {
        dispatch(setLoadingState(false));

        console.log(err);
      }
    };
    if (searchText === "") {
      handleDelete();
      setError("Iltimos joy nomini kiriting!!!");
    } else {
      dispatch(setLoadingState(true));

      fetchData();
    }
  };

  return (
    <main className="max-lg:w-11/12 lg:w-10/12 sm:w-full  mt-[144px] max-w-[1440px] mx-auto flex flex-col justify-center items-center   max-sm:items-start lg:items-center gap-[24px]">
      <section className="flex flex-col gap-[8px] sm:w-11/12 lg:w-[70%]">
        <h1 className="text-[#fdfdfd] font-[600] text-[30px]">Joyni izlash</h1>
        <p className="text-[16px] font-[400] text-[#98A2B3]">
          O’z biznesingizni bizning ma’lumotlar bazamizdan izlang
        </p>
      </section>
      <div className="max-sm:w-full sm:w-11/12 lg:w-[70%] h-[1px] bg-[#333]"></div>
      <section className="max-sm:w-full sm:w-11/12 lg:w-[70%] step-content sm:p-[56px] flex flex-col gap-[8px]">
        <label className="text-[14px] font-[500] text-[#CECFD2]" htmlFor="text">
          Joyni izlash
        </label>
        <div className="grid grid-cols-2 max-lg:grid-cols-4 w-full gap-[16px]">
          <div className="max-lg:col-span-3 relative w-full">
            <input
              onChange={handleSearch}
              className={`${
                error ? "border-red-400" : "border-[#fdfdfd4d]"
              } contact-input text-[#85888E] outline-none w-full bg-[#1d1d20] px-[14px] py-[8px] rounded-[8px] shadow-input border-[#333741] border-[1px]`}
              type="text"
              value={searchText}
              placeholder="Joy nomini kiriting"
            />
            <label className="text-[red]" htmlFor="text">
              {error && error}
            </label>

            <motion.img
              onClick={handleDelete}
              initial={{ scale: 0 }}
              animate={{ scale: searchText ? 1 : 0 }}
              className="w-[20px] absolute right-[14px] top-[12px] cursor-pointer"
              src={close}
              alt=""
            />
          </div>
          <div className="max-lg:grid-cols-1 h-full">
            <button
              onClick={handleSubmit}
              className="sm:w-[88px] px-[14px] py-[10px] flex gap-[6px] bg-[#2970FF] rounded-[8px]"
            >
              <img className="" src={search} alt="" />
              <h1 className="max-sm:hidden text-[14px] font-[500] text-white">
                Izlash
              </h1>
            </button>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          active && (
            <>
              {searchText && place.length > 0 && (
                <section className="flex gap-[16px] flex-col mt-[16px]">
                  <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                    Topilgan joylar
                  </h1>
                  <div className="w-full h-[1px] bg-[#333]"></div>
                  {place.map((item, idx) => (
                    <NavLink
                      to={`/search-place/${item.id}`}
                      key={idx}
                      className="hover:bg-[#1d1d20] p-2 rounded-[8px] transition-all ease-in-out duration-300 flex flex-col gap-[8px] cursor-pointer hover:bg-[#"
                    >
                      <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                        {item.name}
                      </h1>
                      <article className="grid grid-cols-2 max-sm:grid-cols-1 gap-[12px] sm:gap-[32px]">
                        <div className="flex gap-[8px]">
                          <img src={location} alt="" />
                          <p className="text-[14px] font-[400] text-[#94969C]">
                            {item.full_address
                              ? item.full_address
                              : "Telefon raqam mavjud emas!"}
                          </p>
                        </div>
                        {item.phone || item.phone2 ? (
                          <div className="flex gap-[8px]">
                            <img
                              className="w-[20px] h-[20px]"
                              src={phone}
                              alt=""
                            />
                            <p className="text-[14px] font-[400] text-[#94969C]">
                              {item.phone && !item.phone2 && item.phone}
                              {item.phone2 && !item.phone && item.phone2}
                            </p>
                          </div>
                        ) : (
                          <div className="flex gap-[8px]">
                            <img
                              className="w-[20px] h-[20px]"
                              src={phone}
                              alt=""
                            />
                            <p className="text-[14px] font-[400] text-[#94969C]">
                              Telefon raqam mavjud emas!
                            </p>
                          </div>
                        )}
                      </article>
                    </NavLink>
                  ))}
                  {countPlaces / 10 > 10 && (
                    <Pagination pageCount={countPlaces / 10} />
                  )}
                </section>
              )}
              {searchText && place.length === 0 && (
                <section className="flex gap-[16px] flex-col mt-[16px]">
                  <h1 className="text-[14px] font-[500] text-[#CECFD2]">
                    Ma’lumot topilmadi
                  </h1>
                  <div className="w-full h-[1px] bg-[#333]"></div>
                  <div className="w-full flex justify-center items-center flex-col gap-[24px]">
                    <div className="flex flex-col gap-1 justify-center items-center w-[160px]">
                      <img src={empty} alt="" />
                      <p className="text-[14px] font-[400] text-[#CECFD2] text-center">
                        Siz izlagan joy ma’lumotlar bazasidan topilmadi
                      </p>
                    </div>

                    <button
                      onClick={handleAddPlace}
                      className="px-[14px] py-[10px] flex items-center gap-[6px] bg-[#2970FF] rounded-[8px]"
                    >
                      <img src={plus} alt="" />
                      <h1 className="text-[14px] font-[500]">Joy qo’shish</h1>
                    </button>
                  </div>
                </section>
              )}
            </>
          )
        )}
      </section>
    </main>
  );
};

export default SearchPlace;
