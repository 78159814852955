import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData, setStepperCheck } from "../../../reducer/redux";
import { t } from "i18next"; // Ensure that you import the 't' function
import { Tooltip } from "@mui/material";
import { infoCircle } from "../../imgs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import StepperControl from "../stepper-control";

const categories = {
  food: [
    {
      id: 1,
      name: "Fast Food",
      type: "fast_food",
    },
    {
      id: 2,
      name: "Kafe",
      type: "cafe",
    },
    {
      id: 3,
      name: "Restoran",
      type: "restaurant",
    },
    {
      id: 4,
      name: "Bar",
      type: "bar",
    },
  ],
  automobiles: [
    {
      id: 1,
      name: "Bankomat",
      type: "atm",
    },
    {
      id: 2,
      name: "Metan",
      type: "metan_station",
    },
    {
      id: 3,
      name: "Propan",
      type: "propan_station",
    },
    {
      id: 4,
      name: "Dizel",
      type: "dizel_station",
    },
    {
      id: 5,
      name: "Benzin",
      type: "benzin_station",
    },
    {
      id: 6,
      name: "Elektro quvvatlash",
      type: "electr_station",
    },
    {
      id: 7,
      name: "Avtomoyka",
      type: "car_wash",
    },
    {
      id: 8,
      name: "Avto ustaxona",
      type: "auto_repair_shop",
    },
  ],
  sport: [
    {
      id: 1,
      name: "Trenajor zal",
      type: "gym",
    },
  ],
  services: [
    {
      id: 1,
      name: "Dorixona",
      type: "pharmacy",
    },
    {
      id: 2,
      name: "Kosmetolog",
      type: "beautician",
    },
    {
      id: 3,
      name: "Kosmetika",
      type: "cosmetics",
    },
    {
      id: 4,
      name: "Gozallik Saloni",
      type: "beauty_salon",
    },
    {
      id: 5,
      name: "Barbershop",
      type: "barber_shop",
    },
    {
      id: 6,
      name: "Stomotolog",
      type: "dental_clinic",
    },
    {
      id: 7,
      name: "Metro",
      type: "subway_station",
    },
    {
      id: 8,
      name: "Oziq-Ovqat dokoni",
      type: "supermarket",
    },
    {
      id: 9,
      name: "Kiyim-kechak dokoni",
      type: "clothing_store",
    },
  ],
  others: [
    {
      id: 1,
      name: "Qurilish dokoni",
      type: "xozmag",
    },
    {
      id: 2,
      name: "Tikishxona",
      type: "custom_tailor",
    },
    {
      id: 3,
      name: "Ximchistka",
      type: "chemical_cleaning",
    },
    {
      id: 4,
      name: "Medservis",
      type: "medical_clinic",
    },
    {
      id: 5,
      name: "Davlat xizmatlari",
      type: "political",
    },
    {
      id: 6,
      name: "Masjid",
      type: "mosque",
    },
  ],
};

const PlaceType = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { fetchPlace, currentStep } = useSelector((state) => state.events);
  const [checkedItems, setCheckedItems] = useState({});
  let w = document.documentElement.clientWidth;

  const handleCheckboxChange = (category, itemName, itemType) => {
    const items = { ...checkedItems };
    items[category] = items[category] || {};
    items[category][itemName] = !items[category][itemName];
    setCheckedItems(items);

    const newPlaceType = {
      ...fetchPlace.place_type,
      [itemType]: {
        ...(fetchPlace.place_type[itemType] || {}),
        [itemName]: items[category][itemName],
      },
    };

    dispatch(setFetchData({ ...fetchPlace, place_type: newPlaceType }));
  };

  useEffect(() => {
    if (fetchPlace.place_type) {
      dispatch(setStepperCheck(false));
    } else {
      dispatch(setStepperCheck(true));
    }
  }, [fetchPlace]);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
   <>
     <h1 className="text-[29px] font-[600] flex items-center">
       {t("step4")}
       <ClickAwayListener onClickAway={() => setOpen(false)}>
         <div
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
         >
           <Tooltip
            title={t("step4_about")}
            arrow
            placement={w > 640 ? "right" : "top"}
            className="cursor-pointer"
            open={open}
            onClick={handleClick}
           >
             <img
              src={infoCircle}
              alt="info"
              className="w-[20px] h-[20px] ml-[12px] mt-1"
              onClick={() => setOpen(true)}
             />
           </Tooltip>
         </div>
       </ClickAwayListener>
     </h1>
     <p className="text-[#98A2B3] font-[400] text-[16px]">{t("choose_type")}</p>
     <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>
     <div className="grid grid-rows-2 max-sm:grid-rows-3 grid-flow-col gap-2">
       {Object.entries(categories).map(([category, items]) => (
        <div key={category}>
          <h1 className="text-[18px] font-[500] text-white">
            {t(category)} {/* Translate the category name */}
          </h1>
          {items.map((item) => (
           <div key={item.id} className="flex justify-start items-center gap-1">
             <input
              className="checkbox-style w-4 h-4 text-blue-600 rounded ring-offset-gray-800 bg-gray-700 border-gray-600"
              type="checkbox"
              id={`${category}-${item.id}`}
              name={item.name}
              checked={fetchPlace.place_type[item.type]?.[item.name]}
              onChange={() =>
               handleCheckboxChange(category, item.name, item.type)
              }
             />
             <label htmlFor={`${category}-${item.id}`}>
               {t(`categories.${item.type}`)} {/* Translate the item name */}
             </label>
           </div>
          ))}
        </div>
       ))}
     </div>
     <StepperControl currentStep={currentStep} handleClick={() => {}} validateInputs={() => true} />
   </>
  );
};

export default PlaceType;
