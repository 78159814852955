import React, {useEffect, useRef, useState} from "react";
import {trash, upload} from "../imgs";
import {useDispatch, useSelector} from "react-redux";
import {setFetchData, setStepperCheck} from "../../../reducer/redux";
import {t} from "i18next";
import {Tooltip} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {infoCircle} from "../../imgs";
import StepperControl from "../stepper-control";

const Photos = () => {
 const [open, setOpen] = React.useState(false);
 const [imageError, setImageError] = useState(false); // Error state for image upload
 const dispatch = useDispatch();
 const {fetchPlace, currentStep} = useSelector((state) => state.events);
 const fileInputRef = useRef(null);
 let w = document.documentElement.clientWidth;

 // Trigger file input click when user clicks on the "upload" box
 const handleFileInputClick = () => {
  fileInputRef.current.click();
 };

 // Handle file uploaded and dispatch the file to Redux store
 const handleFileUploaded = (e) => {
  const file = e.target.files[0];
  if (file) {
   setImageError(false); // Clear any existing error when a file is selected
  }
  dispatch(
   setFetchData({
    ...fetchPlace,
    image: file,
   })
  );
 };

 // Check if image is uploaded and update the stepper check
 useEffect(() => {
  if (fetchPlace.image) {
   dispatch(setStepperCheck(false)); // Enable next step if image is uploaded
  } else {
   dispatch(setStepperCheck(true)); // Disable next step if no image
  }
 }, [fetchPlace, dispatch]);

 // Validate the image upload before proceeding to the next step
 const validateInputs = () => {
  if (!fetchPlace.image) {
   setImageError(true); // Set error if no image is uploaded
   return false;
  }
  return true;
 };

 // Tooltip handling for information
 const handleClick = () => {
  setOpen(!open);
 };

 return (
  <div>
   <h1 className="text-[29px] font-[600] flex items-center">
    {t("step2")}
    <ClickAwayListener onClickAway={() => setOpen(false)}>
     <div onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Tooltip
       title={t("step2_about")}
       arrow
       placement={w > 640 ? "right" : "top"}
       className="cursor-pointer"
       open={open}
       onClick={handleClick}
      >
       <img src={infoCircle} alt="info" className="w-[20px] h-[20px] ml-[12px] mt-1" onClick={() => setOpen(true)}/>
      </Tooltip>
     </div>
    </ClickAwayListener>
   </h1>
   <div className="w-full h-[1px] bg-[#333] my-[16px]"></div>

   <section className="flex justify-start max-sm:flex-col items-center gap-[24px]">
    {/* File upload box */}
    <div
     onClick={handleFileInputClick}
     className={`max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] flex flex-col justify-center items-center px-[24px] py-[16px] bg-[#1d1d20] rounded-[24px] border-[2px] border-solid 
     ${imageError ? 'border-red-400' : 'border-[#333741]'} hover:border-[#2970FF] cursor-pointer`}
    >
     <img src={upload} alt=""/>
     <h1 className="text-[14px] mt-[12px] font-[500] text-[#FDFDFD] text-center">
      {t("upload_photo")}
     </h1>
     <p className="mt-[4px] text-[12px] font-[400] text-[#94969C] text-center">
      SVG, PNG, JPG (max. 800x400px)
     </p>
    </div>

    {/* Show image preview if image is uploaded */}
    {fetchPlace.image && (
     <div className="max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] relative">
      <img
       className="max-sm:w-full w-[200px] max-sm:h-[200px] h-[144px] object-cover rounded-[24px]"
       src={URL.createObjectURL(fetchPlace.image)}
       alt="Uploaded Preview"
      />
      {/* Trash icon to remove the uploaded image */}
      <img
       className="bg-white rounded-full p-[5px] absolute right-[12px] cursor-pointer top-[12px]"
       src={trash}
       alt="Delete"
       onClick={() => dispatch(setFetchData({ ...fetchPlace, image: null }))}
      />
     </div>
    )}
    {/* Display an error message if no image is uploaded */}
    {imageError && (
     <p className="text-red-400 text-sm mt-[8px]">
      {t("image_required")}
     </p>
    )}
   </section>

   {/* Stepper Control */}
   <StepperControl
    currentStep={currentStep}
    handleClick={() => {}}
    validateInputs={validateInputs}  // Pass the validation function
   />

   {/* Hidden file input */}
   <input
    type="file"
    name="file"
    hidden
    ref={fileInputRef}
    onChange={handleFileUploaded}
    className="file-input"
   />
  </div>
 );
};

export default Photos;
